<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-container" @click.stop>
        <div class="pricing-container">
          <div class="action-container">
            <div class="action-info">
              <p class="action-info-header">
                Bionluk <span>freelancer üyeliği</span>
              </p>
              <p class="action-info-pricing">69.90<span>TL</span></p>
              <p class="action-info-description">* Sınırsız üyelik, tek seferlik 69.90TL</p>
            </div>
            <div class="action-button" @click="continueApplication()">
              <span>Devam Et</span>
            </div>
          </div>
          <div class="separator"></div>
          <div class="info-container">
            <div class="info-inner" v-for="info in detailInfo">
              <img class="info-tick" src="https://gcdn.bionluk.com/site/icon/green_tick.svg"/>
              <p>{{info.name}}</p>
              <!--
              <div class="pricing-soon" v-if="info.soon">İlk 30 gün bedava</div>
              -->
              <span  v-if="info.detail.length" data-balloon="Bionluk'ta bicoin'lerini kullanarak görünürlüğünü artırırsın." data-balloon-pos="up" data-balloon-length="medium" >
                 <img class="info-detail" src="https://gcdn.bionluk.com/site/icon/tansel_red_info.svg"/>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>

</template>

<script>

  export default {
    name: "src-pages-modals-pricing-v1",
    data() {
      return {
        detailInfo: [
          {
            name: 'Sınırsız Portfolyo Yükleme',
            soon: false,
            detail: ''
          },
          {
            name: 'Sınırsız Saklama Alanı',
            soon: false,
            detail: ''
          },
          {
            name: 'Tüm Alıcı İsteklerini Görme ve Teklif Verme',
            soon: false,
            detail: ''
          },
          {
            name: 'Kişiye Özel Teklif Verme',
            soon: false,
            detail: ''
          },
          {
            name: 'Ücretsiz İş İlanı Oluşturma',
            soon: false,
            detail: ''
          },
          {
            name: 'Sana Özel Freelancer Telefon Hattı',
            soon: true,
            detail: ''
          },
          {
            name: 'İlk 1 Ay boyunca VIP Destek ',
            soon: false,
            detail: ''
          },
          {
            name: 'Hediye 1000 bicoin ',
            soon: false,
            detail: 'Burada coin kazanacaksin'
          }

        ]
      }
    },
    methods: {
      hide() {
        this.$store.commit(this.types.DISABLE_MODAL);
      },

      continueApplication() {
        this.hide();
      }
    },

    created() {
    }
  }

</script>

<style scoped lang="scss">

  .modal-mask {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    background-color: rgba(0, 0, 0, .8);
  }

  .portfolio-modal-close-button {
    position: absolute;
    top: 20px;
    right: 160px;
    z-index: 999799 !important;
    cursor: pointer;
    width: 24px;
    height: 24px;
  }

  .modal-container {
    font-family: 'sofia-pro';
    margin: auto;
    padding: 0;
    width: calc(100vw - 20px) !important;
    height: calc(100vh - 20px);
    max-width: 810px;
    max-height: 405px;
    background-color: #ffffff;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #ffffff;
    /*overflow-y: scroll;*/
    position: relative;
  }

  .pricing-container {
    padding: 36px 31px 35px 29px;
    max-height: 334px;
    .separator {
      margin-top: 34px;
      margin-bottom: 30px;
      width: calc(100% + 60px);
      border-top: solid 1px #dbdcdc;
      opacity: 0.5;
      margin-left: -29px;
    }
  }

  .action-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .info-container {
    max-height: 190px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

  }

  @media screen and (max-width: 660px) {
    .info-container {
      max-height: none;
    }

    .modal-container {
      max-height: 600px;
    }
  }

  .action-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .action-info-header {
    font-size: 24px;
    color: #000;
    font-weight: 300;
    span {
      font-weight: bold;
    }
  }

  .action-info-pricing {
    font-size: 52px;
    color: #000;
    font-weight: 500;
    span {
      font-size: 32px;
      font-weight: 400;
    }

  }

  .action-info-description {
    color: #8b95a1;
    font-size: 14px;
    margin-top: 8px;
  }

  .action-button {
    background-color: #fd4056;
    padding: 2px 0px 3px;
    width: 245px;
    height: 55px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: #ff0042;
    }
    span {
      color: #fff;
      font-size: 20px;
      font-weight: 400;
    }
  }

  .info-inner {
    display: flex;
    flex-direction: row;
    margin-bottom: 17px;
    .info-tick {
      margin-right: 9px;
    }

    .info-detail {
      margin-left: 9px;
    }

    .pricing-soon {
      width: 61px;
      height: 16px;
      border-radius: 8px;
      font-size: 10px;
      color: #fff;
      background-color: #000;
      line-height: 16px;
      text-align: center;
      margin-left: 9px;
    }
  }
</style>
